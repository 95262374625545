import React from 'react'
import img from '../../images/fala-rf-cialo.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const FalaRfCialo = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Fala RF | Modelowanie Sylwetki'
                    description='Fala radiowa ma działanie kosmetyczne, powoduje miejscowy wzrost
          temperatury w obrębie tkanek poddanych zabiegowi, zwiększa ukrwienie i
          odżywienie skóry.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/fala-rf'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Fala Rf</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='fala rf zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <p>
                                        Fala radiowa ma działanie kosmetyczne, powoduje
                                        miejscowy wzrost temperatury w obrębie tkanek
                                        poddanych zabiegowi, zwiększa ukrwienie i
                                        odżywienie skóry. Podczas zabiegu wykorzystywane
                                        jest prąd szybkozmienny o wysokim napięciu.
                                        Podczas zabiegu dochodzi do wytwarzania ciepła,
                                        które rozgrzewa naskórek, skórę właściwą i tkankę
                                        podskórną. Wzrost temperatury wody znajdującej się
                                        w skórze właściwej wywołuje natychmiastowe zmiany
                                        w kolagenie: zwiększenie objętości i obkurczenie
                                        włókien. Stare, rozciągnięte włókna zostają
                                        zniszczone i zastąpione nowymi, prawidłowo
                                        zbudowanymi. Produkcja białka kolagenowego trwa
                                        ok. 4 tygodni, po czym nowo powstały kolagen ulega
                                        dalszym przekształceniom.
                                    </p>
                                    <p>
                                        Zabiegi z wykorzystywaniem fali RF powodują
                                        ujędrnienie skóry, poprawę jej elastyczności i
                                        redukują tkankę tłuszczową. Wykonywane są w serii
                                        zabiegów w odstępie dwóch dni, 2 razy w tygodniu.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>FALA RF - WYBRANA PARTIA</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    FALA RF - WYBRANA PARTIA - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 247 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    FALA RF - WYBRANA PARTIA -{' '}
                                                    <span>PAKIET 8 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 1357 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    FALA RF - WYBRANA PARTIA -{' '}
                                                    <span>PAKIET 12 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 1787 zł
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default FalaRfCialo
